const types = {
    SELECT_TOPIC: "SELECT_TOPIC",
    UPDATE_BLOCK: "UPDATE_BLOCK",
    TOGGLE_BLOCK: "TOGGLE_BLOCK",
    TOMORROW_COMES: "TOMORROW_COMES",
    SWAP_DATE: "SWAP_DATE",
    REFRESH_DATA: "REFRESH_DATA",
    FETCH_SUCCESS: "FETCH_SUCCESS",
    FETCH_ERROR: "FETCH_ERROR",
    FETCH_PENDING: "FETCH_PENDING"
};

const selectTopic  = topic => ({
    type: types.SELECT_TOPIC,
    payload: topic
});

const updateBlock  = block => ({
    type: types.UPDATE_BLOCK,
    payload: block
});

const toggleBlock  = block => ({
    type: types.TOGGLE_BLOCK,
    payload: block
});

const tomorrowComes  = date => ({
    type: types.TOMORROW_COMES,
    payload: date
});

const swapDate  = date => ({
    type: types.SWAP_DATE,
    payload: date
});

const fetchSuccess  = data => ({
    type: types.FETCH_SUCCESS,
    payload: data
});

const fetchPending  = () => ({
    type: types.FETCH_PENDING
});

const fetchError  = error => ({
    type: types.FETCH_ERROR,
    payload: error
});

export default {
    selectTopic,
    updateBlock,
    toggleBlock,
    tomorrowComes,
    swapDate,
    fetchSuccess,
    fetchPending,
    fetchError,
    types
  };
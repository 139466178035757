import ACTIONS from "./actions";
import { parseDayLS, storeDayLS, parsePastDaysTopicsLS } from "./utils/lsOperations";
import { generateFreqData, generatePeriodData } from "./utils/chartOperations";
import { uploadDay } from "./utils/apiOperations";
import _ from "lodash";

const defaultColor = '#FFF';
const defaultTopicNames = [
                'Sleep',
                'Need',
                'Work',
                'Social',
                'Goal',
                'Waste'
            ];
const defaultTopicColors = [
                '#6622CC',
                '#A755C2',
                '#90FCF9',
                '#63B4D1',
                '#F49E4C',
                '#F4442E'
            ];

let defaultCalendarColors = defaultTopicColors.slice(0);
defaultCalendarColors.push('#20B2AA');

// Load userId from url or local storage if it exists
const params = (new URL(document.location)).searchParams;
let firstPull = true;
if (params.has('id')) {
    window.localStorage['userId'] = params.get('id');
} else if (!window.localStorage['userId']) {
    let userId = Math.random().toString(36).substring(2) + Date.now().toString(36);
    if (userId.length < 20) {
        userId = userId + Math.random().toString(36).substring(2, 2 + 20 - userId.length);
    }
    window.localStorage['userId'] = userId;
    firstPull = false;
}

// Load blocks from local storage, if they exist and parse out dates
const tzOffset = (new Date()).getTimezoneOffset() * 60000;
let today = (new Date(Date.now() - tzOffset)).toISOString().substr(0, 10);
let dayData = parseDayLS(today, defaultColor, defaultTopicColors, defaultTopicNames);
let weekBlocks = parsePastDaysTopicsLS(7);
let weekDates = Array(6).fill().map((_, i) => (
    new Date(Date.parse(today) - tzOffset - i * 24 * 60 * 60000)).toISOString().substr(0, 10));
weekDates.unshift(today);

const defaultState = {
  defaultColor: defaultColor,
  topicIndex: -1,
  topicKey: {
    topics: defaultTopicNames,
    colors: defaultTopicColors,
    calColors: defaultCalendarColors
  },
  blocks: dayData.blocks,
  topics: dayData.topics,
  toggleBlock: null,
  today: today,
  weekDates: weekDates,
  weekBlocks: weekBlocks,
  periodChartData: generatePeriodData(weekBlocks, defaultTopicNames.indexOf('Goal')),
  freqChartData: generateFreqData(weekBlocks, defaultTopicNames),
  todayFreqChartData: generateFreqData([weekBlocks[0]], defaultTopicNames),
  avgFreqChartData: generateFreqData(weekBlocks.slice(1,6), defaultTopicNames, 6),
  selectedDay: today,
  selectedIndex: -1,
  userId: window.localStorage['userId'],
  loadingStatus: Array(7).fill(false),
  firstPull: firstPull
};

const brutalReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ACTIONS.types.SELECT_TOPIC: {
            let newState = _.cloneDeep(state);
            if (state.topicIndex !== action.payload) {
                newState.topicIndex = action.payload;
            } else {
                newState.topicIndex = -1;
            }
            return newState;
        }

        case ACTIONS.types.UPDATE_BLOCK: {
            let newState = _.cloneDeep(state);
            const block = action.payload;
            let destTopic = state.topicIndex;
            let destColor = state.defaultColor;
            if (state.topicIndex !== -1) {
                destColor = state.topicKey.colors[state.topicIndex]
            }

            if (state.toggleBlock !== null && state.toggleBlock !== block) {
                const minBlock = Math.min(state.toggleBlock, block);
                const maxBlock = Math.max(state.toggleBlock, block);
                for (let updating = minBlock;
                    updating <= maxBlock;
                    updating++
                ) {
                    newState.blocks[updating] = destColor;
                    newState.topics[updating] = destTopic;
                }
            } else {
                if (state.blocks[block] === destColor) {
                    destColor = state.defaultColor;
                    destTopic = -1;
                }
                newState.blocks[block] = destColor;
                newState.topics[block] = destTopic;
            }
    
            newState.toggleBlock = null;
            storeDayLS(state.selectedDay, newState.topics, state.topicKey.topics);
            uploadDay(newState.userId, newState.selectedDay, newState.topics, newState.topicKey.topics);

            // Update the past 7 days of data used by the charts
            newState.weekBlocks = parsePastDaysTopicsLS(7);
            newState.periodChartData = generatePeriodData(newState.weekBlocks, state.topicKey.topics.indexOf('Goal'));
            newState.freqChartData = generateFreqData(newState.weekBlocks, state.topicKey.topics);
            newState.todayFreqChartData = generateFreqData([newState.weekBlocks[0]], state.topicKey.topics);
            newState.avgFreqChartData = generateFreqData(newState.weekBlocks.slice(1,6), state.topicKey.topics, 6);
            return newState;
        }

        case ACTIONS.types.TOGGLE_BLOCK: {
            let newState = _.cloneDeep(state);
            const block = action.payload;
            let destTopic = state.topicIndex;
            let destColor = state.defaultColor;

            if (state.topicIndex !== -1) {
                destColor = state.topicKey.colors[state.topicIndex];
            }

            newState.blocks[block] = destColor;
            newState.topics[block] = destTopic;
            newState.toggleBlock = block;

            storeDayLS(state.selectedDay, newState.topics, newState.topicKey.topics);
            return newState;
        }

        case ACTIONS.types.TOMORROW_COMES: {
            const dates = action.payload;

            let newState = _.cloneDeep(state);
            newState.blocks = Array(96).fill(state.defaultColor);
            newState.topics = Array(96);
            newState.today = dates.today;
            newState.selectedDay = dates.today;

            // Keep dates updated
            newState.weekDates.unshift(dates.today);
            newState.weekDates.pop();

            // Update the past 7 days of data used by the charts
            newState.weekBlocks = parsePastDaysTopicsLS(7);
            newState.periodChartData = generatePeriodData(newState.weekBlocks, state.topicKey.topics.indexOf('Goal'));
            newState.freqChartData = generateFreqData(newState.weekBlocks, state.topicKey.topics)
            newState.todayFreqChartData = generateFreqData([newState.weekBlocks[0]], state.topicKey.topics);
            newState.avgFreqChartData = generateFreqData(newState.weekBlocks.slice(1,6), state.topicKey.topics, 6);

            return newState;
        }

        case ACTIONS.types.SWAP_DATE: {
            const date = action.payload.day;
            const index = action.payload.index;

            let newState = _.cloneDeep(state);
            newState.selectedDay = date;
            newState.selectedIndex = index;

            let storedData = parseDayLS(
                                date,
                                newState.defaultColor,
                                newState.topicKey.colors,
                                newState.topicKey.topics
                                );
            newState.blocks = storedData.blocks;
            newState.topics = storedData.topics;

            return newState;
        }

        case ACTIONS.types.FETCH_SUCCESS: {
            const data = action.payload;
            const dayIndex = state.weekDates.indexOf(data['date']);
            if (dayIndex !== -1) {
                let newState = _.cloneDeep(state);
                newState.loadingStatus[dayIndex] = false;

                if (data['blocks'].length) {
                    newState.weekBlocks[dayIndex] = data['blocks'];
                    storeDayLS(data['date'], data['blocks'], newState.topicKey.topics);

                    if (!dayIndex) {
                        let storedData = parseDayLS(
                                            data['date'],
                                            newState.defaultColor,
                                            newState.topicKey.colors,
                                            newState.topicKey.topics
                                            );
                        newState.blocks = storedData.blocks;
                        newState.topics = storedData.topics;
                    }

                    newState.periodChartData = generatePeriodData(newState.weekBlocks, state.topicKey.topics.indexOf('Goal'));
                    newState.freqChartData = generateFreqData(newState.weekBlocks, state.topicKey.topics)
                    newState.todayFreqChartData = generateFreqData([newState.weekBlocks[0]], state.topicKey.topics);
                    newState.avgFreqChartData = generateFreqData(newState.weekBlocks.slice(1,6), state.topicKey.topics, 6);
                }

                return newState;
            } else {
                return state;
            }
        }

        case ACTIONS.types.FETCH_PENDING: {
            let newState = _.cloneDeep(state);
            newState.loadingStatus = Array(7).fill(true);
            newState.firstPull = false;
            // Reset selected
            newState.selectedIndex = -1;
            newState.selectedDay = newState.today;

            return newState;
        }

        case ACTIONS.types.FETCH_ERROR: {
            const data = action.payload;
            const dayIndex = state.weekDates.indexOf(data);
            if (dayIndex !== -1) {
                //TODO: Add offline icon
                let newState = _.cloneDeep(state);
                newState.loadingStatus[dayIndex] = false;
                return newState;
            } else {
                return state;
            }
        }

        default: {
            return state;
        }
    }
}

export default brutalReducer;
import ACTIONS from '../actions';

const BASE_URL = 'https://api.brupla.com/v1';
// const BASE_URL = 'http://localhost:3000';

const fetchDay = (user, day) => {
    return dispatch => {
        fetch(`${BASE_URL}/blocks?user=${user}&date=${day}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'text/plain'
                  }
            })
        .then(res => {
            return res.json();
        })
        .then(data => {
            if (data.error) {
                throw(data.error);
            }
            dispatch(ACTIONS.fetchSuccess(data));
        }).catch(error => {
            dispatch(ACTIONS.fetchError(day));
        })
    }
}

const uploadDay = (user, date, blocks, topics) => {
    const dateToUpload = {
        user: user,
        date: date,
        blocks: blocks,
        key: topics
    };

    fetch(`${BASE_URL}/blocks`, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dateToUpload)
    })
}

export {
    uploadDay,
    fetchDay
}
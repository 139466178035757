import React from 'react';

import '../style/LoadingSplash.css';

class LoadingSplash extends React.Component {
    static defaultProps = {
        loading: false,
        height: 100,
        spinnerHeight: 100
    }

    render () {
        const style = {
            display: this.props.loading ? 'block': 'none',
            height: this.props.height + 'px'
        };

        return (
            <div className="LoadingSplash" style={style}>
                <div className="loadingHouse" style={{marginTop: this.props.spinnerHeight}}>
                    <div className="loadingSpinner"></div>
                </div>
            </div>
        );
    }
}

export default LoadingSplash;
import React from 'react';
import { connect } from 'react-redux';
import { Bar } from 'react-chartjs-2';

import '../style/Charts.css';

class Charts extends React.Component {
    render () {
        const freqData = {
            labels: [
                'Sleep',
                'Need',
                'Work',
                'Social',
                'Goal',
                'Waste'
            ],
            datasets: [
                {
                    backgroundColor: [
                        'rgba(102, 34, 204,1)',
                        'rgba(167, 85, 194,1)',
                        'rgba(144, 252, 249,1)',
                        'rgba(99, 180, 209,1)',
                        'rgba(244, 158, 76,1)',
                        'rgba(244, 68, 46,1)'
                    ],
                    borderWidth: 0,
                    hoverBackgroundColor: [

                    ],
                    data: this.props.freqData
                }]
        };

        const todayFreqData = {
            labels: [
                'Sleep',
                'Need',
                'Work',
                'Social',
                'Goal',
                'Waste'
            ],
            datasets: [
                {
                    backgroundColor: [
                        'rgba(102, 34, 204,1)',
                        'rgba(167, 85, 194,1)',
                        'rgba(144, 252, 249,1)',
                        'rgba(99, 180, 209,1)',
                        'rgba(244, 158, 76,1)',
                        'rgba(244, 68, 46,1)'
                    ],
                    borderWidth: 0,
                    hoverBackgroundColor: [

                    ],
                    data: this.props.todayFreqData
                }]
        };

        const avgFreqData = {
            labels: [
                'Sleep',
                'Need',
                'Work',
                'Social',
                'Goal',
                'Waste'
            ],
            datasets: [
                {
                    backgroundColor: [
                        'rgba(102, 34, 204,1)',
                        'rgba(167, 85, 194,1)',
                        'rgba(144, 252, 249,1)',
                        'rgba(99, 180, 209,1)',
                        'rgba(244, 158, 76,1)',
                        'rgba(244, 68, 46,1)'
                    ],
                    borderWidth: 0,
                    hoverBackgroundColor: [

                    ],
                    data: this.props.avgFreqData
                }]
        };

        const periodData = {
            labels: [
                '0-4',
                '4-8',
                '8-12',
                '12-16',
                '16-20',
                '20-24'
            ],
            datasets: [{
                backgroundColor: 'rgba(244,158,76,1)',
                hoverBackgroundColor: 'rgba(244,158,76,0.4)',
                borderWidth: 0,
                data: this.props.periodData
            }]
        }

        const chartOptions = {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true,
                        fontColor: 'white'
                    },
                }],
              xAxes: [{
                    ticks: {
                        fontColor: 'white'
                    },
                }]
            }
        };

        return (
            <div className="Charts">
                <div className="todayFreqChart">
                    <p>Today's Block Count</p>
                    <Bar
                        data={todayFreqData}
                        options={chartOptions}
                    />
                </div>
                <div className="avgFreqChart">
                    <p>Avg. Block Count</p>
                    <Bar
                        data={avgFreqData}
                        options={chartOptions}
                    />
                </div>
                <div className="freqChart">
                    <p>Total Block Count</p>
                    <Bar
                        data={freqData}
                        options={chartOptions}
                    />
                </div>
                <div className="prodTimeChart">
                    <p>Weekly Productive Periods</p>
                    <Bar
                        data={periodData}
                        options={chartOptions}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    periodData: state.periodChartData,
    freqData: state.freqChartData,
    todayFreqData: state.todayFreqChartData,
    avgFreqData: state.avgFreqChartData
});

export default connect(mapStateToProps, {}) (Charts);

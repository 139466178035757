import React from 'react';
import { connect } from 'react-redux';

import ACTIONS from "../actions";

import '../style/Palette.css';

class Palette extends React.Component {
    constructor(props) {
        super(props);
        this.updateColor = this.updateColor.bind(this);
      }

    updateColor(colorIndex) {
        this.props.selectTopic(colorIndex);
    }
    
    render() {      
        let styles = [];
        let textStyles = [];

        for (let value of this.props.colors) {
            styles.push({
                "backgroundColor": value,
            });
            textStyles.push({});
        }

        if (this.props.selectedIndex !== -1) {
            let divStyle = textStyles[this.props.selectedIndex];
            divStyle.borderBottom = "1px solid black";
        }

        return (
            <div className="Palette">
                <button className="TopicButton" style={styles[0]} onClick={() => this.updateColor(0)}><p style={textStyles[0]}>{this.props.topics[0]}</p></button>
                <button className="TopicButton" style={styles[1]} onClick={() => this.updateColor(1)}><p style={textStyles[1]}>{this.props.topics[1]}</p></button>
                <button className="TopicButton" style={styles[2]} onClick={() => this.updateColor(2)}><p style={textStyles[2]}>{this.props.topics[2]}</p></button>
                <button className="TopicButton" style={styles[3]} onClick={() => this.updateColor(3)}><p style={textStyles[3]}>{this.props.topics[3]}</p></button>
                <button className="TopicButton" style={styles[4]} onClick={() => this.updateColor(4)}><p style={textStyles[4]}>{this.props.topics[4]}</p></button>
                <button className="TopicButton" style={styles[5]} onClick={() => this.updateColor(5)}><p style={textStyles[5]}>{this.props.topics[5]}</p></button>
            </div>
        );
      }
}

const mapStateToProps = state => ({
    activeColor: state.color,
    topics: state.topicKey.topics,
    colors: state.topicKey.colors,
    selectedIndex: state.topicIndex
});

const mapDispatchToProps = dispatch => ({
    selectTopic: topic => { dispatch(ACTIONS.selectTopic(topic)) }
});

export default connect(mapStateToProps, mapDispatchToProps) (Palette);
import React from 'react';
import { connect } from 'react-redux';

import ACTIONS from './actions.js'
import Calendar from './containers/Calendar';
import Charts from './containers/Charts';
import Header from './containers/Header';
import Stats from './containers/Stats';
import DateLabel from './containers/DateLabel';
import MiniCalendarView from './containers/MiniCalendarView';
import Palette from './containers/Palette';

import './style/App.css';

class App extends React.Component{
  static defaultProps = {
    loading: Array(7).fill(false)
  }

  constructor(props) {
    super(props);

    this.state = {
      activeBlock: 0,
      selectedIndex: -1
    };

    this.checkTime = this.checkTime.bind(this);
    this.miniCalClick = this.miniCalClick.bind(this);

  }

  componentDidMount () {
    this.checkTime();
    setInterval(this.checkTime, 10000);
  }

  checkTime() {
      const date = new Date();
      const hours = date.getHours();
      const minutes = date.getMinutes();

      // Update active block whenever we notice it's off
      // Useful for suspended apps (e.g. homepage apps)
      const newActiveBlock = hours * 4 + Math.floor(minutes / 15);
      if (newActiveBlock !== this.state.activeBlock) {
          this.setState({ activeBlock: newActiveBlock });
      }

      const tzoffset = (new Date()).getTimezoneOffset() * 60000;
      const actualToday = (new Date(Date.now() - tzoffset)).toISOString().substr(0, 10);
      if (this.props.today !== actualToday) {
        // A new day begins
        // Clear old state and update date
        this.props.tomorrowComes({
            yesterday: this.props.today,
            today: actualToday
        });
      }
  }

  miniCalClick(day, index) {
    if (index !== this.props.selectedIndex) {
      this.props.swapDate({day: day, index: index});
    } else {
      this.props.swapDate({day: this.props.today, index: -1});
    }
  }

  render() {
    let activeBlockSwap = this.state.activeBlock;
    let calDates = this.props.weekDates.slice();
    let calBlocks = this.props.weekBlocks.slice();
    // TODO: This could be removed if the logic for mini cal is updated
    calDates.reverse();
    calBlocks.reverse();

    if (this.props.selectedIndex !== -1) {
      calDates[this.props.selectedIndex] = this.props.today;
      calBlocks[this.props.selectedIndex] = calBlocks[6];
      activeBlockSwap = -1;
    }

    return (
        <div className="App clearfix">
          <Header />
          <div className="AppField">
            <DateLabel />
            <Palette />
            <Calendar activeBlock={activeBlockSwap} loading={this.props.loading[0]} />
          </div>
          <div className="Metrics">
            <Stats activeBlock={this.state.activeBlock} />
            <div className="PreviousDays">
                <MiniCalendarView loading={this.props.loading[1]} date={calDates[5]} index={5} blocks={calBlocks[5]} miniCalClick={this.miniCalClick} />
                <MiniCalendarView loading={this.props.loading[2]} date={calDates[4]} index={4} blocks={calBlocks[4]} miniCalClick={this.miniCalClick} />
                <MiniCalendarView loading={this.props.loading[3]} date={calDates[3]} index={3} blocks={calBlocks[3]} miniCalClick={this.miniCalClick} />
                <MiniCalendarView loading={this.props.loading[4]} date={calDates[2]} index={2} blocks={calBlocks[2]} miniCalClick={this.miniCalClick} />
                <MiniCalendarView loading={this.props.loading[5]} date={calDates[1]} index={1} blocks={calBlocks[1]} miniCalClick={this.miniCalClick} />
                <MiniCalendarView loading={this.props.loading[6]} date={calDates[0]} index={0} blocks={calBlocks[0]} miniCalClick={this.miniCalClick} />
            </div>
            <Charts activeBlock={this.state.activeBlock} />
            <div className="Footer">
            © 2020 <a href="https://twitter.com/handsometony">Tony Abraham</a>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  blocks: state.blocks,
  today: state.today,
  selectedIndex: state.selectedIndex,
  weekBlocks: state.weekBlocks,
  weekDates: state.weekDates,
  loading: state.loadingStatus
});

const mapDispatchToProps = dispatch => ({
  tomorrowComes: (date) => dispatch(ACTIONS.tomorrowComes(date)),
  swapDate: (date) => dispatch(ACTIONS.swapDate(date))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
import React from 'react';

import '../style/Logo.css';

class Logo extends React.Component {
    static defaultProps = {
        activeColor: '#ccc',
        numberVisible: false,
        blockId: '',
        active: false,
        blockWidth: 1,
        logoWidth: 218,
        logoHeight: 48
    }

    componentDidMount() {
        let colors = [
            '#6622CC',
            '#A755C2',
            '#90FCF9',
            '#63B4D1',
            '#F49E4C',
            '#F4442E'
        ];

        this.shuffle(colors);
        this.populateLogo(colors, this.props.blockWidth);
    }

    setupCanvas(canvas) {
        // From https://www.html5rocks.com/en/tutorials/canvas/hidpi/
        // Get the device pixel ratio, falling back to 1.
        var dpr = window.devicePixelRatio || 1;
        // Get the size of the canvas in CSS pixels.
        var rect = canvas.getBoundingClientRect();
        // Give the canvas pixel dimensions of their CSS
        // size * the device pixel ratio.
        canvas.width = rect.width * dpr;
        canvas.height = rect.height * dpr;
        var ctx = canvas.getContext('2d');
        // Scale all drawing operations by the dpr, so you
        // don't have to worry about the difference.
        ctx.scale(dpr, dpr);
        return ctx;
      }

    shuffle(a) {
        // From https://stackoverflow.com/a/6274381/2233394
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    populateLogo(colors, blockWidth) {
        const canvas = this.refs.logo;
        const logo = [
            [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,0,1,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
            [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0],
            [1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
            [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,0,1,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0],
            [1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
            [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]
        ]
        if (canvas) {
            const ctx = this.setupCanvas(canvas);
            let startX = 0;
            let startY = 0;
            let indent = 0;

            for(let h  = 0; h < 6; h++) {
                indent = h * blockWidth * 8 + h * 5;
                for(let i = 0; i < 96; i++) {
                    startX = i % 8 * blockWidth + indent + .5;
                    startY = Math.floor(i / 8) * blockWidth + .5;
                    if (logo[h][i]) {
                        ctx.fillStyle= colors[h];
                        ctx.fillRect(startX, startY, blockWidth, blockWidth);
                    }
                }
            }
        }
    }

    render() {
        return (
            <canvas
                className="Logo"
                id={this.props.id}
                ref="logo"
                width={this.props.logoWidth}
                height={this.props.logoHeight}
            />
        );
      }
}

export default Logo;
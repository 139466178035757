import React from 'react';
import { connect } from 'react-redux';

import ACTIONS from '../actions.js'
import '../style/DateLabel.css';

class DateLabel extends React.Component {
    constructor(props) {
        super(props);

        this.handleTodayClick = this.handleTodayClick.bind(this);
      }

    static defaultProps = {
        selectedDay: '01',
        today: '01',
        calColors: []
    }

    handleTodayClick() {
        this.props.swapDate({day: this.props.today, index: -1});
    }

    render() {

        const colorIndex = this.props.selectedDay.substr(-2) % this.props.calColors.length;
        const colorStyle = {
            backgroundColor: this.props.calColors[colorIndex]
        };
        if (this.props.selectedDay === this.props.today) {
            return (
                <div className="DateLabel">
                    <div className="DateLabelTopActive" style={colorStyle} />
                    {this.props.selectedDay.substr(-2)}
                </div>
            );
        } else {
            return (
                <div className="DoubleDateLabel">
                    <div className="DateLabelActive">
                        <div className="DateLabelTopActive" style={colorStyle} />
                        {this.props.selectedDay.substr(-2)}
                    </div>
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="DateLabelInactive" onClick={this.handleTodayClick} >
                        <div className="DateLabelTopInactive" />
                        {this.props.today.substr(-2)}
                    </div>
                </div>
            );
        }
      }
}

const mapStateToProps = state => ({
    selectedDay: state.selectedDay,
    today: state.today,
    calColors: state.topicKey.calColors
});

const mapDispatchToProps = dispatch => ({
    swapDate: (date) => { dispatch(ACTIONS.swapDate(date)) }
});

export default connect(mapStateToProps, mapDispatchToProps) (DateLabel);
import React from 'react';
import { connect } from 'react-redux';

import LoadingSplash from './LoadingSplash';
import '../style/MiniCalendarView.css';

class DateLabel extends React.Component {
    static defaultProps = {
        colors: [],
        calColors: [],
        date: '00',
        index: 0,
        blocks: [],
        loading: false
    }
    
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.populateCalendar = this.populateCalendar.bind(this);
    }

    componentDidMount() {
        this.populateCalendar();
    }

    componentDidUpdate() {
        this.populateCalendar();
    }

    populateCalendar() {
        const blocks = this.props.blocks;
        const canvas = this.refs["miniCal" + this.props.index];
        if (canvas) {
            const ctx = canvas.getContext('2d');
            const blockWidth = 15;
            let startX = 0;
            let startY = 0;
            for(let i = 0; i < blocks.length; i++) {
                startX = i % 8 * blockWidth + .5;
                startY = Math.floor(i / 8) * blockWidth + .5;
                if (blocks[i] !== -1) {
                    ctx.fillStyle = this.props.colors[blocks[i]];
                } else {
                    ctx.fillStyle = '#FFF';
                }
                ctx.fillRect(startX, startY, blockWidth, blockWidth);

                if (blocks[i] === -1) {
                    ctx.strokeStyle = '#EEE';
                } else {
                    ctx.strokeStyle = this.props.colors[blocks[i]];
                }
                ctx.strokeRect(startX, startY, blockWidth, blockWidth);

            }
        }
    }

    handleClick() {
        if (!this.props.loading) {
            this.props.miniCalClick(this.props.date, this.props.index);
        }
    }

    render() {
        const colorIndex = this.props.date.substr(-2) % this.props.calColors.length;
        const labelTopStyle = {
            backgroundColor: this.props.calColors[colorIndex]
        };

        return (
            <div onClick={this.handleClick} className="MiniCalendarView">
                <LoadingSplash loading={this.props.loading} height={250} spinnerHeight={125}/>
                <div className="MiniDateLabel">
                    <div className="MiniDateLabelTop" style={labelTopStyle} />
                    {this.props.date.substr(-2)}
                </div>
                <canvas
                    className="MiniCalendar"
                    ref={"miniCal" + this.props.index}
                    width={120}
                    height={198}
                />
            </div>
        );
      }
}

const mapStateToProps = state => ({
    defaultColor: state.defaultColor,
    colors: state.topicKey.colors,
    topics: state.topicKey.topics,
    calColors: state.topicKey.calColors
});

export default connect(mapStateToProps) (DateLabel);
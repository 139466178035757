import React from 'react';
import { connect } from 'react-redux';

import '../style/Charts.css';

class Stats extends React.Component {
    static defaultProps = {
        topics: [],
        activeBlock: 0
    }

    render () {
        const parsedData = [].concat.apply([], this.props.weekBlocks);

        const blocksLeft = this.props.topics.slice(this.props.activeBlock);
        const freeBlocksLeft = blocksLeft.filter(x => x === -1).length;
        const goalIndex = this.props.topicKey.indexOf('Goal');
        const wastIndex = this.props.topicKey.indexOf('Waste');

        const countedGoal = parsedData.filter(x => x === goalIndex).length;
        const countedWast = parsedData.filter(x => x === wastIndex).length;
        let prodScore = 'N/A';
        if (countedGoal + countedWast > 0) {
            prodScore = Math.round(100 * countedGoal / (countedGoal + countedWast));
        }

        return (
            <div className="Stats">
                <p className="freeBlocks">Blocks Remaining: {freeBlocksLeft}</p>
                <p className="prodScore">Productivity Score: {prodScore}%</p>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    topicKey: state.topicKey.topics,
    topics: state.topics,
    weekBlocks: state.weekBlocks
});

export default connect(mapStateToProps, {}) (Stats);

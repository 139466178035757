const parseDateTopicsLS = (date) => {
    if (localStorage[date]) {
        const parsedDate = JSON.parse(localStorage[date] || '{}')
        return parsedDate['topics']
    } else {
        return null;
    }
}

const parsePastDaysTopicsLS = (dayCount) => {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000;
    let weekData = [];
    let day;
    let offset;
    let parsedDay;

    for (let x = 0; x < dayCount; x++) {
        offset = x * 1000 * 60 * 60 * 24 + tzoffset;
        day = (new Date(Date.now() - offset)).toISOString().substr(0, 10);
        parsedDay = parseDateTopicsLS(day);
        if (parsedDay) {
            weekData.push(parsedDay);
        } else {
            weekData.push(Array(96).fill(-1));
        }
    }
    return weekData;
}

const parseDayLS = (date, defaultColor, colorList, topicList) => {
    let blocks = Array(96).fill(defaultColor);
    let topics = Array(96).fill(-1);

    if (localStorage[date]) {
        const storedDay = JSON.parse(localStorage[date])
        const topicKey = storedDay['topicKey'] || [];
        const storedTopics = storedDay['topics'] || [];

        for(let i = 0; i < storedTopics.length; i++) {
            let topicIndex = storedTopics[i];

            if (topicIndex !== -1) {
                let colorIndex = topicList.indexOf(topicKey[topicIndex]);
                if (colorIndex !== -1) {
                    blocks[i] = colorList[colorIndex];
                    topics[i] = colorIndex;
                }
            }
        }
    }

    return { blocks, topics };
}

const storeDayLS = (date, topics, topicList) => {
    const storedDay = {
        topicKey: topicList,
        topics: topics
    };
    localStorage[date] = JSON.stringify(storedDay);
}

export {
    parseDateTopicsLS,
    parsePastDaysTopicsLS,
    parseDayLS,
    storeDayLS
};
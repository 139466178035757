const generateFreqData = (data, topicKey, denom=1) => {
    const goalIndex = topicKey.indexOf('Goal');
    const wastIndex = topicKey.indexOf('Waste');
    const sleeIndex = topicKey.indexOf('Sleep');
    const needIndex = topicKey.indexOf('Need');
    const workIndex = topicKey.indexOf('Work');
    const sociIndex = topicKey.indexOf('Social');

    const parsedData = [].concat.apply([], data);

    const countedGoal = parsedData.filter(x => x === goalIndex).length/denom;
    const countedWast = parsedData.filter(x => x === wastIndex).length/denom;
    const countedSlee = parsedData.filter(x => x === sleeIndex).length/denom;
    const countedWork = parsedData.filter(x => x === workIndex).length/denom;
    const countedNeed = parsedData.filter(x => x === needIndex).length/denom;
    const countedSoci = parsedData.filter(x => x === sociIndex).length/denom;

    return [
        countedSlee,
        countedNeed,
        countedWork,
        countedSoci,
        countedGoal,
        countedWast
    ];
}

const generatePeriodData = (data, goalIndex) => {
    let periods = [0,0,0,0,0,0];
    for (let x = 0; x < data.length; x++) {
        for (let y = 0; y < 6; y ++) {
            periods[y] = periods[y] + data[x].slice(y * 16, y * 16 + 16).filter(x => x === goalIndex).length;
        }
    }

    return periods;
}

export {
    generatePeriodData,
    generateFreqData
}
import React from 'react';
import { connect } from 'react-redux';

import ACTIONS from '../actions';
import '../style/Block.css';

class Block extends React.Component {
    static defaultProps = {
        activeColor: '#ccc',
        numberVisible: false,
        blockId: '',
        active: false
    }

    constructor(props) {
        super(props);

        this.blockClick = this.blockClick.bind(this);
        this.blockDoubleClick = this.blockDoubleClick.bind(this);
    }

    blockClick() {
        this.props.updateBlock(this.props.blockId);
    }

    blockDoubleClick() {
        this.props.toggleBlock(this.props.blockId);
    }

    render () {
        let divStyle = {
            "backgroundColor": this.props.color
        };

        if (this.props.color !== '#FFF') {
            divStyle["border"] = "1px solid " + this.props.color;
        }

        // const checkerColor = "#EEE";
        // if (this.props.color === '#FFF') {
        //     if ((Math.floor(this.props.blockId / 8)) % 2) {
        //         if (this.props.blockId % 2) {
        //             divStyle = {"backgroundColor": checkerColor};
        //         }
        //     } else {
        //         if (!(this.props.blockId % 2)) {
        //             divStyle = {"backgroundColor": checkerColor};
        //         }
        //     }
        // }

        if (this.props.active && !this.props.numberVisible) {
            divStyle.backgroundImage = `url("block.png")`;
            divStyle.backgroundPosition = 'center';
            divStyle.backgroundSize = '100%';
            divStyle.backgroundRepeat = 'no-repeat';
        }

        if (this.props.toggled) {
            divStyle.backgroundImage = `url("toggle.gif")`;
            divStyle.backgroundPosition = 'center';
            divStyle.backgroundSize = "45px 45px";
        }

        const textStyle = {
            color: this.props.color,
            background: "black",
            textAlign: "center",
            width: "22px",
            height: "22px",
            lineHeight: "22px",
            margin: "0 auto",
            display: "block"
        }

        return (
            <button style={divStyle} 
                    className="block" 
                    onClick={this.blockClick}
                    onDoubleClick={this.blockDoubleClick}
                    >
                    <span style={this.props.active && this.props.numberVisible ? textStyle: null}>
                        {this.props.numberVisible ? this.props.blockId / 4 : ''}
                    </span>
            </button>
        );
    }
}

const mapStateToProps = state => ({
    activeColor: state.color
});

const mapDispatchToProps = dispatch => ({
    updateBlock: block => { dispatch(ACTIONS.updateBlock(block)) },
    toggleBlock: block => { dispatch(ACTIONS.toggleBlock(block)) }
});

export default connect(mapStateToProps, mapDispatchToProps) (Block);
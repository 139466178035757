import React from 'react';
import { connect } from 'react-redux';

import ACTIONS from '../actions.js';
import Block from './Block';
import LoadingSplash from './LoadingSplash';


import '../style/Calendar.css';


class Calendar extends React.Component {
    render () {
        let calendar = []
        for (let row = 0; row < 12; row++) {
            let calendarRow = []
            for (let cols = 0; cols < 8; cols++) {
                const step = row * 8 + cols;
                calendarRow.push(<Block blockId={step}
                                        key={step}
                                        numberVisible={!(step % 4)}
                                        color={this.props.blocks[step]}
                                        active={this.props.activeBlock === step}
                                        toggled={this.props.toggleBlock === step}
                                        />);
            }
            calendar.push(<div key={'row' + row}className='CalendarRow'>{calendarRow}</div>)
        }

        return (
            <div className="Calendar">
                <LoadingSplash loading={this.props.loading} height={542} spinnerHeight={241}/>
                {calendar}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    blocks: state.blocks,
    toggleBlock: state.toggleBlock
});

const mapDispatchToProps = dispatch => ({
    clearBlocks: (date) => { dispatch(ACTIONS.clearBlocks(date)) }
});


export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
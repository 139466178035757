import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ACTIONS from '../actions';
import { fetchDay } from "../utils/apiOperations";
import Logo from './Logo';
import '../style/Header.css';

class Header extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          displayIntro: !window.localStorage['returnVisit']
        };

        this.menuClick = this.menuClick.bind(this);
        this.refreshData = this.refreshData.bind(this);
    }

    componentDidMount() {
        if (this.props.firstPull) {
            this.refreshData();
        }
    }

    menuClick() {
        this.setState({displayIntro: !this.state.displayIntro});
        window.localStorage['returnVisit'] = true;
    }

    refreshData() {
        if (this.props.loading.every(i => !i)) {
            this.props.fetchPending();
            for (let date of this.props.weekDates) {
                this.props.fetchDay(this.props.userId, date);
            }
        }
    }

    render() {
        let menuButtonStyle = { backgroundImage: "url('menu.svg')", marginTop: "0px" };
        let introStyle = { display: "none"};
        let refreshStyle = { backgroundImage: "url('refresh-white.svg')"};
        let headerStyle = { backgroundColor: "black"};

        if (this.state.displayIntro) {
            menuButtonStyle.backgroundImage = "url('close.svg')";
            menuButtonStyle.marginTop = "2px";
            introStyle.display = "block";
            refreshStyle.backgroundImage = "url('refresh-black.svg')";
            headerStyle.backgroundColor = "white";
        }

        const userLink = "https://www.brupla.com?id=" + window.localStorage['userId'];

        return (
        <div className="Header" style={headerStyle}>
            <div className="TopRow">
                <button className="RefreshButton"
                    style={refreshStyle}
                    onClick={this.refreshData}
                />
                <a className="LogoLink" id="BigLogoLink" href={userLink}>
                    <Logo
                        id="BigLogo"
                        blockWidth={4}
                        logoWidth={218}
                        logoHeight={60} />
                </a>
                <a className="LogoLink" id="LittleLogoLink" href={userLink}>
                    <Logo
                        id="LittleLogo"
                        blockWidth={3}
                        logoWidth={170}
                        logoHeight={44} />
                </a>
                <button className="MenuButton"
                    style={menuButtonStyle}
                    onClick={this.menuClick}
                />
            </div>
                <div className="IntroText" style={introStyle}>
                    <p>We all have 24 hours to spend each day. This dashboard will give you insight into how you spend those hours.</p>
                    <p>The grid visualizes your day in 15 minute blocks. Tap a label above the grid and then a block to color it. Labels are explained <a href="https://brupla.com/blog/posts/categories" target="_blank" rel="noopener noreferrer">here</a>.</p>
                    <div className="tutorialBlock">
                        <img className="tutorialImage" src="./tutorial1.png" alt="a mouse cursor clicking once on a label" />
                        <img className="tutorialImage" src="./tutorial2.png" alt="a mouse cursor clicking once on a block" style={{marginLeft: "44px"}} />
                    </div>
                    <p>Double tap a block to start a streak, and tap another block to end it.</p>
                    <div className="tutorialBlock">
                        <img className="tutorialImage" src="./tutorial3.png" alt="a mouse cursor double clicking on a block" />
                        <img className="tutorialImage" src="./tutorial4.png" alt="a mouse cursor clicking on another block to finish a streak" style={{marginLeft: "44px"}} />
                    </div>
                    <p>Your private URL is: <a href={userLink}>{userLink}</a>. Email this url to yourself and open your dashboard on another device. For easy access, save this app to the homescreen of your phone.</p>
                    <p>Your data is uploaded every time you add a block. <a href="https://brupla.com/blog/posts/privacy" target="_blank" rel="noopener noreferrer">Read</a> how Brupla respects your privacy.</p>
                    <p><button className='TextButton' onClick={this.menuClick}>Close menu</button></p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userId: state.userId,
    today: state.today,
    weekDates: state.weekDates,
    loading: state.loadingStatus,
    firstPull: state.firstPull
});

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchPending: () => ACTIONS.fetchPending(),
    fetchDay: (user, day) => fetchDay(user, day)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps) (Header);